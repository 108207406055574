import styles from './FilterButtons.css'

export function FilterButtons({ items, activeItems, handleClick }) {
  return (
    <section className={styles.component}>
      {items.map((item, index) => (
        <FilterButton
          key={index}
          onClick={() => handleClick(item)}
          isActive={activeItems.includes(item.key)}
          title={item.title}
        />
      ))}
    </section>
  )
}

function FilterButton({ title, isActive, onClick }) {
  return (
    <button
      className={cx(styles.componentFilterButton, isActive && styles.active)}
      type='button'
      data-x='filter-button'
      {...{ onClick }}
    >
      {title}
    </button>
  )
}
