import { HeadingSm, HeadingMd } from './Heading'
import { Icon } from './Icon'
import { ImageCover } from './Image'
import { MetaData } from './MetaData'
import { useComponentContext } from './ComponentContextProvider'
import { pseudoRandom } from '@kaliber/math'

import iconPlay from '../images/icons/play.raw.svg'

import styles from './Card.css'

export function Card({ children, styleContext, scaleOnHover = true }) {
  return (
    <div
      className={cx(styles.component, scaleOnHover && styles.hover)}
      data-style-context={styleContext}
    >
      <div className={styles.contentWrapper}>
        {children}
      </div>
    </div>
  )
}

export function CardArticle({ title, subtitle, hero, tags = [] }) {
  return (
    <Card styleContext="light">
      <div className={styles.componentArticle}>
        {hero?.asset && (
          <ImageCover aspectRatio={16 / 9} image={hero} />
        )}
        <div className={styles.articleContent}>
          {Boolean(tags?.length) && <MetaData layoutClassName={styles.tagsLayout} alignCenter {...{ tags }} />}
          <span className={styles.title}>
            <HeadingMd h={2} {...{ title }} />
          </span>
          {subtitle && (
            <span className={styles.intro}>{subtitle}</span>
          )}
        </div>
      </div>
    </Card>
  )
}

export function CardVideo({ title, video }) {
  return (
    <Card styleContext="blue">
      <div className={styles.componentVideo}>
        {video.poster && <ImageCover
          image={video.poster}
          aspectRatio={16 / 9}
        />}
        <div className={cx(styles.videoWrapper, styles.wrapperLayout)}>
          <VideoContent {...{ title }} />
        </div>
      </div>
    </Card>
  )
}

function VideoContent({ title }) {
  return (
    <div className={styles.componentVideoContent}>
      <PlayIcon layoutClassName={styles.playIconLayout} />
      <HeadingSm h={2} {...{ title }} />
    </div>
  )
}

function PlayIcon({ layoutClassName }) {
  return (
    <div className={cx(styles.componentPlayIcon, layoutClassName)}>
      <Icon layoutClassName={styles.iconLayout} icon={iconPlay} />
    </div>
  )
}

export function CardFact({ fact, scaleOnHover = true, tags = [], createdAt = undefined }) {
  const { factFormatting, factValue, factSuffix } = fact
  const styleContexts = ['light', 'blue-dark', 'orange']

  return (
    <Card
      styleContext={styleContexts[Math.floor(pseudoRandom(createdAt) * styleContexts.length)]}
      {...{ scaleOnHover }}
    >
      <div className={styles.componentFact}>
        {Boolean(tags?.length) && <MetaData layoutClassName={styles.tagsLayout} alignCenter {...{ tags }} />}
        <FactValue {...{ factValue, factFormatting }} layoutClassName={styles.valueLayout} />
        <FactSuffix {...{ factSuffix }} layoutClassName={styles.suffixLayout} />
      </div>
    </Card>
  )
}

function FactSuffix({ factSuffix, layoutClassName }) {
  return <div className={cx(styles.componentFactSuffix, layoutClassName)}>{factSuffix}</div>
}

function FactValue({ factValue, factFormatting, layoutClassName }) {
  const { language } = useComponentContext()

  return (
    <div className={cx(styles.componentFactValue, layoutClassName)}>
      {format(factValue, factFormatting)}
    </div>
  )

  function format(value, formatting) {
    const numberFormatLanguage = [language, language.toUpperCase()].join('-')

    switch (formatting) {
      case 'currencyDollar':
        return new Intl.NumberFormat(numberFormatLanguage, {
          style: 'currency',
          currency: 'USD',
          maximumSignificantDigits: 3,
        }).format(value)
      case 'currencyEuro':
        return new Intl.NumberFormat(numberFormatLanguage, {
          style: 'currency',
          currency: 'EUR',
          maximumSignificantDigits: 3,
        }).format(value)
      case 'numberUs':
        return new Intl.NumberFormat('en-US').format(value)
      case 'numberEuro':
        return new Intl.NumberFormat('de-DE').format(value)
      case 'percentage':
        return new Intl.NumberFormat(numberFormatLanguage, {
          style: 'percent',
          maximumFractionDigits: 2,
        }).format(value / 100)
      default:
        return value
    }
  }
}

export function CardPodcastSmall({ title, podcast  }) {
  return <PodcastBase className={styles.componentPodcastSmall} {...{ title, podcast  }} />
}

export function CardPodcastLarge({ title, podcast  }) {
  return <PodcastBase className={styles.componentPodcastLarge} {...{ title, podcast  }} />
}

function PodcastBase({ title, podcast, className }) {
  const src = getCorrectSpotifyUrl(podcast.src)

  return (
    <Card styleContext={!src ? 'dark-blue' : 'blue'}>
      <div className={cx(styles.componentPodcastBase, className)}>
        <Frame layoutClassName={styles.frameLayout} {...{ src, title, podcast }} />
        <Skeleton layoutClassName={styles.skeletonLayout} isError={!src} />
      </div>
    </Card>
  )

}
function Frame({ title, src, layoutClassName }) {
  const [ready, setReady] = React.useState(false)

  return (
    <iframe
      // @ts-ignore
      allowtransparency='true'
      onLoad={() => setReady(true)}
      className={cx(styles.componentFrame, layoutClassName, ready && styles.isReady)}
      allow="encrypted-media"
      {...{ src, title }}
    />
  )
}

function Skeleton({ isError, layoutClassName }) {
  return (
    <div className={cx(styles.componentSkeleton, isError && styles.isError, layoutClassName)}>
      <div className={cx(styles.imageSkeleton, styles.imageLayout)} />
      <div className={cx(styles.titleSkeleton, styles.titleLayout)} />
      <div className={cx(styles.playSkeleton, styles.playLayout)} />
    </div>
  )
}

function getCorrectSpotifyUrl(src) {
  const pattern = /https:\/\/open.spotify.com\/(?<type>.*)\/(?<id>[^?]*)(?:\?|)(.*)?/
  const { groups } = src?.match(pattern) ?? {}
  const { id, type } = groups ?? {}

  return (id && type) ? `https://open.spotify.com/embed/${type}/${id}` : null
}
