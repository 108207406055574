import PublishedDate from './PublishedDate.universal'
import { PublishedDate as PublishedDateForUniversal } from './PublishedDate'
import { useComponentContext } from './ComponentContextProvider'

import styles from './MetaData.css'

export function MetaData({ tags, date = undefined, layoutClassName = undefined, alignCenter = false }) {
  const { isUniversal } = useComponentContext()
  const PublishedDateComponent = isUniversal ? PublishedDateForUniversal : PublishedDate

  return (
    <div className={cx(styles.component, layoutClassName, alignCenter && styles.alignCenter)}>
      <Tags {...{ tags }} />
      {date && <PublishedDateComponent {...{ date }} />}
    </div>
  )
}

function Tags({ tags }) {
  return (
    <>
      {tags && tags.map((tag, xid) => (
        <span className={styles.tag} key={`${tag._id}-${xid}`}>{tag.title.toUpperCase()}</span>
      ))}
    </>
  )
}

