import ReactPlayer from 'react-player/lazy'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'

import styles from './VideoWithChapters.css'

const PLAYERCONFIG = {
  vimeo: {
    playerOptions: {
      title: false,
    },
  },
}

export function VideoWithChapters({
  video,
  chaptersTitle,
  onPlay = undefined,
  onPause = undefined,
  onNavigation = undefined
}) {
  const { src, chapters } = video
  const [playing, setPlaying] = React.useState(false)
  const isMounted = useRenderOnMount()
  const playerRef = React.useRef(null)

  if (!isMounted) return null

  return (
    <div className={styles.component}>
      <div className={cx(styles.playerWrapper)}>
        <ReactPlayer
        // eslint-disable-next-line @kaliber/layout-class-name
          className={styles.player}
          controls
          config={PLAYERCONFIG}
          width='100%'
          height='100%'
          onPlay={handlePlay}
          onPause={handlePause}
          url={src}
          ref={playerRef}
          {...{ playing }}
        />
      </div>

      {Boolean(chapters?.length) && (
        <div className={styles.chapters}>
          <div className={styles.chaptersWrapper}>
            <span className={styles.header}>{chaptersTitle}</span>
            {chapters.map(( chapter, i ) => (
              <TimestampButton
                key={i}
                onClick={() => goToTimestamp(chapter)}
                {...{ chapter }}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )

  function goToTimestamp(chapter) {
    const { title, timestamp } = chapter

    setPlaying(true)
    playerRef.current.seekTo(
      convertTimestampToSeek(
        playerRef.current.getDuration(),
        timestamp
      ),
      'fraction'
    )
    if (onNavigation) onNavigation(getVideoMetaDataProps())
  }

  function handlePlay() {
    setPlaying(true)
    if (onPlay) onPlay(getVideoMetaDataProps())
  }

  function handlePause() {
    setPlaying(false)
    if (onPause) onPause(getVideoMetaDataProps())
  }

  function getVideoMetaDataProps() {
    const currentTime = playerRef.current.getCurrentTime()

    return {
      type: 'video',
      duration: playerRef.current.getDuration(),
      progress: currentTime === null ? 0 : currentTime
    }
  }
}

function TimestampButton({ chapter, onClick }) {
  const { timestamp, title } = chapter

  return (
    <button
      data-x="goto-video-chapter"
      className={styles.componentTimestampButton}
      type="button"
      {...{ onClick }}
    >
      <span className={styles.title}>{title}</span>
      <span className={styles.timestamp}>{timestamp}</span>
    </button>
  )
}

function convertTimestampToSeek(durationInSeconds, timestamp) {
  const timeStampInSeconds = timeStringToSeconds(timestamp)

  return (timeStampInSeconds / (durationInSeconds / 100) / 100)
}

function timeStringToSeconds(timeString) {
  const timeStringArray = timeString.split(':').map(x => parseInt(x, 10))

  if (timeStringArray.length < 3 || timeStringArray.some(x => Number.isNaN(x)) ) {
    throw new Error(`Unsupported string given ${timeString}`)
  }

  return (timeStringArray[0] * 3600) + (timeStringArray[1] * 60) + (timeStringArray[2])
}
