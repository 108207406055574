import { Icon } from './Icon'

import styles from './CallToActionLink.css'

import iconArrow from './../images/icons/arrow.raw.svg'

export function CallToActionLink({ title, href, dataX,  target = '_self' }) {
  return (
    <a
      className={styles.component}
      data-x={dataX}
      {...{ href, target }}
    >
      {title}
      <span className={styles.icon}>
        <Icon layoutClassName={styles.iconLayout} icon={iconArrow} />
      </span>
    </a>
  )
}
