/** @type {React.Context<{ dayjs: any, language: string, sanityClientConfig: any, __: any, isUniversal: boolean }>} */
const componentContext = React.createContext(null)

export function ComponentContextProvider({ dayjs, language, sanityClientConfig, __, children, isUniversal = false }) {
  const contextValue = React.useMemo(
    () => ({ dayjs, language, sanityClientConfig, isUniversal, __ }),
    [dayjs, language, sanityClientConfig, isUniversal, __]
  )
  return <componentContext.Provider value={contextValue} {...{ children }} />
}

export function useComponentContext() {
  const contextValue = React.useContext(componentContext)
  if (!contextValue) throw new Error(`Please supply a <ComponentContext /> before using this component.`)

  return contextValue
}
