import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { useComponentContext } from './ComponentContextProvider'

import styles from './PublishedDate.css'

export function PublishedDate({ date }) {
  const { dayjs, language } = useComponentContext()
  const isMounted = useRenderOnMount()

  return (
    <span className={styles.component}>
      {isMounted && (
        dayjs(date).locale(language).format('LL').toUpperCase()
      )}
    </span>
  )
}

