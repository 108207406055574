import styles from './Button.css'

export function ButtonPrimary({ dataX, label, onClick = undefined, disabled = false, type = 'button', layoutClassName = undefined }) {
  return <ButtonBase className={cx(styles.componentPrimary, layoutClassName)} {...{ dataX, label, onClick, type, disabled }} />
}

function ButtonBase({ className, dataX, label, onClick, type, disabled }) {
  return (
    <button
      data-x={dataX}
      aria-label={label}
      className={cx(styles.componentBase, className)}
      {...{ onClick, type, disabled }}
    >
      {label}
    </button>
  )
}
