import {
  Image as ImageBase,
  ImageCropped as ImageCroppedBase,
  ImageCover as ImageCoverBase
} from '@kaliber/sanity-image'
import { useComponentContext } from './ComponentContextProvider'

export function Image({ image, imgProps = {}, layoutClassName = undefined }) {
  const { sanityClientConfig } = useComponentContext()
  return (
    <ImageBase
      sanityConfig={sanityClientConfig}
      imgProps={{ ...imgProps, alt: image?.alt ?? '' }}
      {...{ image, layoutClassName }}
    />
  )
}

export function ImageCropped({ image, aspectRatio, imgProps = {}, layoutClassName = undefined }) {
  const { sanityClientConfig } = useComponentContext()
  return (
    <ImageCroppedBase
      sanityConfig={sanityClientConfig}
      imgProps={{ ...imgProps, alt: image?.alt ?? '' }}
      {...{ image, aspectRatio, layoutClassName }}
    />
  )
}

export function ImageCover({ image, aspectRatio, imgProps = {}, layoutClassName = undefined }) {
  const { sanityClientConfig } = useComponentContext()
  return (
    <ImageCoverBase
      sanityConfig={sanityClientConfig}
      imgProps={{ ...imgProps, alt: image?.alt ?? '' }}
      {...{ image, aspectRatio, layoutClassName }}
    />
  )
}
