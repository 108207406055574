import styles from './Container.css'

export function ContainerLg({ children, dataX = undefined, styleContext = undefined, layoutClassName = undefined }) {
  return <ContainerBase className={cx(styles.componentLg, layoutClassName)} {...{ children, dataX, styleContext }} />
}

function ContainerBase({ children, className, dataX, styleContext }) {
  return (
    <section
      data-x={dataX}
      data-style-context={styleContext}
      className={cx(styles.componentBase, className)}
    >
      <div className={styles.layout}>
        {children}
      </div>
    </section>
  )
}
