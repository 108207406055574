import { useComponentContext } from './ComponentContextProvider'
import { Icon } from './Icon'
import { useListDropdown } from './../machinery/useListDropdown'
import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react'
import { useElementSize } from '@kaliber/use-element-size'

import iconCheck from './../images/icons/check.raw.svg'
import chevronIcon from './../images/icons/chevron-top.raw.svg'
import iconCross from './../images/icons/cross.raw.svg'

import styles from './OverviewFilters.css'

export function OverviewFilters({
  onFilterChange,
  filtersAndValues,
  onReset,
  trackFilterInteraction = undefined,
  trackFilterInteractionMultiValue = undefined
}) {
  const filtersSorted = React.useMemo(
    () => {
      return Object.fromEntries(
        Object.entries(filtersAndValues).sort(([, a], [, b]) => a.display_order - b.display_order)
      )
    },
    [filtersAndValues]
  )

  const activeFilters = mapActiveFilters({ filtersAndValues })

  return (
    <div className={styles.component}>
      {Boolean(activeFilters.length) && (
        <ActiveFilters {...{ onFilterChange, activeFilters, onReset }} />
      )}
      {Object.entries(filtersSorted).map(([filterId, { options, value }]) => {
        const isInitialOpen = Object.keys(filtersAndValues).length === 1 || Boolean((value && (typeof value === 'string' || (Array.isArray(value) && value.length > 0))))

        return (
          <FilterGroup
            key={filterId}
            layoutClassName={styles.filterGroupLayout}
            options={filterId === 'tag' ? sortFilterValuesTotalCount(options) : sortFilterValuesTitle(options)}
            {...{ filterId, onFilterChange, isInitialOpen, value, trackFilterInteraction, trackFilterInteractionMultiValue }}
          />
        )
      })}
    </div>
  )

  function sortFilterValuesTitle(values) {
    return values.sort((a, b) => a.label.localeCompare(b.label))
  }

  function sortFilterValuesTotalCount(values) {
    return values.sort((a, b) => b.total - a.total)
  }

  function mapActiveFilters({ filtersAndValues }) {
    return Object.entries(filtersAndValues).map(([id, filter]) => {
      const activeOptions = filter.options?.filter(option => filter.value.includes(option.id))
      return activeOptions?.length ? [id, activeOptions, filter.value] : undefined
    }).filter(filter => filter !== undefined)
  }
}

function ActiveFilters({ activeFilters, onReset, onFilterChange }) {
  const { __ } = useComponentContext()

  return (
    <div className={styles.componentActiveFilters}>
      <div className={styles.activeFiltersHeader}>{__`selected-filters-title`}</div>
      <div className={styles.activeFilterPills}>
        {activeFilters.map(([filterId, options, value]) => (
          options.map(option => (
            <button
              className={styles.activeFilterPill}
              key={option.id}
              type="button"
              aria-label={__({ filterName: option.label })`reset-filter-label`}
              onClick={() => handleFilterRemoval(filterId, value, option.id)}
            >
              {option.label}
              <span className={cx(styles.iconContainer, styles.iconContainerLayout)}>
                <Icon icon={iconCross} />
              </span>
            </button>
          ))
        ))}
        <button
          className={styles.resetFiltersPill}
          type="button"
          aria-label={__`reset-filters-label`}
          onClick={onReset}
        >
          {__`reset-filters-label-pill`}
          <span className={cx(styles.iconContainer, styles.iconContainerLayout)}>
            <Icon icon={iconCross} />
          </span>
        </button>
      </div>
    </div>
  )

  function handleFilterRemoval( filterId, value, id ) {
    if (filterId === 'country') {
      onFilterChange({ [filterId]: '' })
    } else {
      onFilterChange({ [filterId]: value.filter(x => x !== id) })
    }
  }
}

function FilterGroup({
  layoutClassName,
  options,
  filterId,
  isInitialOpen,
  onFilterChange,
  value,
  trackFilterInteraction,
  trackFilterInteractionMultiValue
}) {
  const [isOpen, setOpen] = React.useState(isInitialOpen)
  const { size: { height }, ref: innerRef } = useElementSize()

  return (
    <fieldset className={cx(styles.componentFilterGroup, layoutClassName)}>
      <FilterHeader
        onClick={() => setOpen(!isOpen)}
        layoutClassName={styles.filterHeaderLayout}
        {...{ isOpen, filterId }}
      />
      <div className={styles.expandContainer} style={{ height: isOpen ? height + 'px' : 0 }}>
        <div ref={innerRef}>
          {filterId === 'country'
            ? <FilterOptionsCountry {...{ options, value }} onFilterChange={handleCountryChange} />
            : (
              filterId === 'tag'
                ? <FilterOptionsPill {...{ filterId, options, value }} onFilterItemChange={handlePillChange} />
                : <FilterOptionsCheckbox {...{ filterId, options, value }} onFilterItemChange={handleCheckboxChange} />
            )
          }
        </div>
      </div>
    </fieldset>
  )

  function handleCountryChange(country) {
    if (Boolean(trackFilterInteraction)) {
      trackFilterInteraction({ name: 'country', value: country, inputType: 'single_select' })
    }

    onFilterChange({ country })
  }

  function handlePillChange({ filterId, id }) {
    updateAndTrackItemChange({ type: 'pill', filterId, id })
  }

  function handleCheckboxChange({ filterId, id }) {
    updateAndTrackItemChange({ type: 'checkbox', filterId, id })
  }

  function updateAndTrackItemChange({ type, filterId, id }) {
    const updatedFilterValue = updateFilterValue({ value, optionId: id })

    if (Boolean(trackFilterInteractionMultiValue)) {
      trackFilterInteractionMultiValue({
        name: filterId,
        value: id,
        inputType: type,
        selected: updatedFilterValue.includes(id),
      })
    }

    onFilterChange({ [filterId]: updatedFilterValue })
  }

  function updateFilterValue({ value, optionId }) {
    const updatedFilterValue = value.includes(optionId) ?
      value.filter(x => x !== optionId) :
      [...value, optionId]

    return updatedFilterValue
  }
}

function FilterOptionsCountry({ options, value, onFilterChange }) {
  const { __ } = useComponentContext()

  return (
    <div className={styles.componentFilterOptionsCountry} data-x='select-country'>
      <Select
        onChange={onFilterChange}
        placeholder={__`filter-country-placeholder`}
        layoutClassName={styles.selectLayout}
        {...{ options, value }}
      />
    </div>
  )
}

function FilterOptionsCheckbox({ filterId, options, value, onFilterItemChange }) {
  return (
    <div className={styles.componentFilterOptionsCheckbox}>
      {options.map(({ id, label }) => (
        <FilterValueItem
          onChange={onFilterItemChange}
          checked={value.includes(id)}
          layoutClassName={styles.filterValueItemLayout}
          key={id}
          {...{ filterId, id, label }}
        />
      ))}
    </div>
  )
}

function FilterOptionsPill({ filterId, options, value, onFilterItemChange }) {
  return (
    <div className={styles.componentFilterOptionsPill}>
      {options.map(({ id, label }) => (
        <Pill
          onChange={() => onFilterItemChange({ filterId, id })}
          checked={value.includes(id)}
          key={id}
          {...{ filterId, id, label }}
        />
      ))}
    </div>
  )
}

function FilterHeader({ onClick, isOpen, filterId, layoutClassName }) {
  const { __ } = useComponentContext()

  return (
    <button
      type='button'
      className={cx(styles.componentFilterHeader, layoutClassName)}
      aria-expanded={isOpen}
      data-x='open-filter-dropdown'
      {... { onClick }}
    >
      <legend className={styles.filterHeader}>
        { __`filter-${filterId.replace('_', '-')}-title` }
      </legend>
      <span className={cx(styles.dropdownChevron, isOpen && styles.rotate)}>
        <Icon icon={chevronIcon} />
      </span>
    </button>
  )
}

function FilterValueItem({ filterId, id, label, onChange, checked, layoutClassName }) {
  return (
    <div className={layoutClassName}>
      <Checkbox
        onChange={handleChange}
        {...{ label, checked, id }}
      />
    </div>
  )

  function handleChange(e) {
    onChange({ filterId, id })
  }
}

function Pill({ checked, id, label, onChange }) {
  return (
    <label htmlFor={id} className={cx(styles.componentPill, checked && styles.pillChecked)}>
      <CheckboxInput layoutClassName={styles.checkboxInputLayout} {...{ onChange, checked, id }} />
      {label}
    </label>
  )
}

function Checkbox({ checked, id, label, onChange }) {
  return (
    <label htmlFor={id} className={styles.componentCheckbox}>
      <CheckboxInput layoutClassName={styles.checkboxInputLayout} {...{ onChange, checked, id }} />
      <div className={styles.checkboxLabel}>
        <div className={styles.checkboxLabelMain}>{label}</div>
      </div>
      <span className={cx(styles.checkboxIndicator, checked && styles.checkboxIndicatorChecked)}>
        {checked && <Icon icon={iconCheck} />}
      </span>
    </label>
  )
}

function CheckboxInput({ onChange, checked, id, layoutClassName }) {
  return (
    <input
      type='checkbox'
      data-x='apply-filter'
      className={cx(styles.componentCheckboxInput, layoutClassName)}
      {...{ onChange, checked, id }}
    />
  )
}

function Select({ value, onChange, options, placeholder, layoutClassName }) {
  const selectedIndex = options.findIndex(x => x.value === value)
  const valueLabel = options.find(x => x.id === value)?.label
  const {
    isOpen,
    setIsOpen,
    context,
    getFloatingProps,
    getReferenceProps,
    getItemProps,
    activeIndex,
  } = useListDropdown({ selectedIndex })

  return (
    <>
      <button
        className={cx(styles.select, !valueLabel && styles.selectNoValue, layoutClassName)}
        {...getReferenceProps({ onClick: () => setIsOpen(!isOpen) })}
        type='button'
      >
        <span>{ valueLabel ?? placeholder }</span>
        <span className={cx(styles.selectChevron, isOpen && styles.selectChevronOpen)}><Icon icon={chevronIcon} /></span>
      </button>
      { isOpen && (
        <FloatingPortal>
          <FloatingFocusManager {...{ context }}>
            <ul className={styles.selectDropdown} {...getFloatingProps()}>
              {[ { id: null, label: placeholder }, ...options].map(({ id, label }, i) => (
                <li
                  key={id}
                  className={cx(styles.selectOption)}
                >
                  <button
                    {...getItemProps(i, label, { onClick: () => {
                      onChange(id)
                      setIsOpen(false)
                    } })}
                    className={cx(styles.selectOptionButton)}
                    data-active={i === activeIndex}
                  >
                    {label}
                  </button>
                </li>
              ))}
            </ul>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  )
}
