import { Icon } from './Icon'

import styles from './SearchBar.css'

import iconCross from './../images/icons/cross.raw.svg'
import iconLookingGlass from './../images/icons/looking-glass.raw.svg'

export function SearchBar({
  placeholder,
  inputValue,
  queryStringValue,
  onClear,
  onSubmit,
  onChange,
  suggestionsFetched = false,
  suggestions = []
}) {
  const hasSuggestions = suggestionsFetched && Boolean(suggestions.length)

  return (
    <section className={styles.component} tabIndex={0} data-x="search-bar">
      <form method='get' className={styles.searchForm} onSubmit={e => onSubmit(e, inputValue)}>
        <Icon icon={iconLookingGlass} layoutClassName={styles.searchIconLayout} />
        <input
          className={styles.searchField}
          type="search"
          name="query"
          autoComplete="off"
          value={inputValue}
          {...{ placeholder, onChange }}
        />
        {queryStringValue &&
        <button
          type="button"
          className={styles.clearSearchButton}
          onClick={onClear}
        >
          <Icon icon={iconCross} />
        </button>
        }
        <button role='search' className={styles.searchSubmit} type='submit' />
      </form>
      {hasSuggestions && (
      <div className={styles.suggestions}>
        {suggestions.map((suggestion, i) => {
          return (
            <a
              className={styles.suggestion}
              href={suggestion.href}
              key={i}
              dangerouslySetInnerHTML={{ __html: suggestion.highlight }}
            />
          )
        })}
      </div>
      )}
    </section>
  )
}
