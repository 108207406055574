import styles from './Heading.css'

export function HeadingXl({ title, h = undefined, layoutClassName = undefined }) {
  return (
    <HeadingBase
      className={cx(styles.componentXl, layoutClassName)}
      {...{ h, title }}
    />
  )
}

export function HeadingLg({ title, h = undefined, layoutClassName = undefined }) {
  return (
    <HeadingBase
      className={cx(styles.componentLg, layoutClassName)}
      {...{ h, title }}
    />
  )
}

export function HeadingMd({ title, h = undefined, layoutClassName = undefined }) {
  return (
    <HeadingBase
      className={cx(styles.componentMd, layoutClassName)}
      {...{ h, title }}
    />
  )
}

export function HeadingSm({ title, h = undefined, layoutClassName = undefined }) {
  return (
    <HeadingBase
      className={cx(styles.componentSm, layoutClassName)}
      {...{ h, title }}
    />
  )
}

function HeadingBase({ title, h, className }) {
  /** @type {any} */
  const HBase = h ? `h${h}` : 'strong'

  return (
    <HBase className={cx(styles.componentBase, className)}>{title}</HBase>
  )
}
