import { Icon } from './Icon'
import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  autoUpdate,
  useDismiss,
  useFloating,
  useInteractions,
  useTransitionStyles,
} from '@floating-ui/react'

import styles from './ContentOverlay.css'

import iconCross from './../images/icons/cross.raw.svg'
import iconCopy from './../images/icons/copy.raw.svg'

export function ContentOverlay({
  children,
  isOpen,
  handleClose,
  closeAriaLabel,
  ContainerElement,
  copyAriaLabel = undefined,
  handleOpenChange = undefined,
  handleCopyToClipBoard = undefined
}) {
  const {
    context,
    isMounted,
    floatingProps,
    transitionStyles
  } = useFloatingOverlay({ isOpen, handleOpenChange })

  return (
    <>
      {isMounted && (
        <FloatingPortal>
          <FloatingFocusManager {...{ context }} returnFocus={false}>
            <FloatingOverlay className={styles.floatingOverlay} lockScroll>
              <div className={styles._rootOverlayBackground}>
                <ContainerElement layoutClassName={styles.containerLayout}>
                  <div {...floatingProps}>
                    <div style={transitionStyles} className={styles._rootOverlay}>
                      <OverlayHeader
                        onClose={handleClose}
                        onCopyToClipBoard={handleCopyToClipBoard}
                        {...{ closeAriaLabel, copyAriaLabel }}
                      />
                      <div className={styles.contentWrapper}>
                        {children}
                      </div>
                    </div>
                  </div>
                </ContainerElement>
              </div>
            </FloatingOverlay>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  )
}

function useFloatingOverlay({ isOpen, handleOpenChange }) {
  const { refs, context } = useFloating({
    whileElementsMounted: autoUpdate,
    onOpenChange: handleOpenChange,
    open: isOpen,
  })

  const dismiss = useDismiss(context, { outsidePressEvent: 'mousedown' })
  const { getFloatingProps } = useInteractions([
    dismiss
  ])

  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    initial: {
      opacity: 0,
      transform: 'translateY(100px)',
    },
    open: {
      opacity: 1,
      transform: 'translateY(0px)',
    },
    duration: 200,
  })

  return {
    refs,
    context,
    isMounted,
    transitionStyles,
    floatingProps: {
      ref: refs.setFloating,
      ...getFloatingProps()
    }
  }
}

function OverlayHeader({ onClose, onCopyToClipBoard, copyAriaLabel, closeAriaLabel }) {
  return (
    <div className={styles.componentOverlayHeader}>
      {(onCopyToClipBoard && copyAriaLabel) && (
        <IconButton
          icon={iconCopy}
          onClick={onCopyToClipBoard}
          ariaLabel={copyAriaLabel}
          dataX="copy-to-clipboard"
        />
      )}
      <IconButton
        icon={iconCross}
        onClick={onClose}
        ariaLabel={closeAriaLabel}
        dataX="close-overlay"
      />
    </div>
  )
}

function IconButton({ icon, onClick, ariaLabel, dataX }) {
  return (
    <button
      type="button"
      aria-label={ariaLabel}
      className={styles.componentIconButton}
      data-x={dataX}
      {...{ onClick }}
    >
      <div className={styles.iconButtonContent}>
        <Icon {...{ icon }} />
      </div>
    </button>
  )
}
